/**
 * Banner 468x60
Leaderboard 728x90
Skyscraper 120x600
Wide Skyscraper 160x600
Half Page ad 300x600
Medium Rectangle 300x250
3:1 Rectangle 300x100
Vertical Links 160x90, 180x90, 200x90
Horizontal Links 468x15, 728x15
Mobile Banner 320x50, 300x50, 480x75, 480x60, 420x37, 320x80, 320x150
Square 300x300
Tablet Landscape Banner 1024x90
Tablet Portrait Banner 768x90
Tablet Full Page (status bar) 1004x768
Pushdown 970x90
IAB Billboard 970x250
Wallpaper (skin) 1800x1000
Mobile Double Banner 320x100
**/
import React from 'react'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import PageMeta from '../components/PageMeta/PageMeta'
import * as BannerStyles from '../components/Banners/TopBanner/TopBanner.module.scss'
import Content from '../components/Content/Content'

const Banners = [
  {
    title: 'Banner',
    height: 60,
    width: 468
  },
  {
    title: 'Leaderboard',
    height: 90,
    width: 728
  },
  {
    title: 'Skyscraper',
    height: 600,
    width: 120
  },
  {
    title: 'Wide Skyscraper',
    height: 600,
    width: 160
  },
  {
    title: 'Half Page ad',
    height: 600,
    width: 300
  },
  {
    title: 'Medium Rectangle',
    height: 250,
    width: 300
  },
  {
    title: '3:1 Rectangle',
    height: 100,
    width: 300
  },
  {
    title: 'Vertical Links',
    height: 90,
    width: 160
  },
  {
    title: 'Horizontal Links',
    height: 15,
    width: 468
  },
  {
    title: 'Mobile Banner',
    height: 50,
    width: 320
  },
  {
    title: 'Square',
    height: 300,
    width: 300
  },
  {
    title: 'Tablet Landscape Banner',
    height: 90,
    width: 1024
  },
  {
    title: 'Tablet Portrait Banner',
    height: 90,
    width: 768
  },
  {
    title: 'Tablet Full Page (status bar)',
    height: 768,
    width: 1004
  },
  {
    title: 'Pushdown',
    height: 90,
    width: 970
  },
  {
    title: 'IAB Billboard',
    height: 250,
    width: 970
  },
  {
    title: 'Wallpaper (skin)',
    height: 1000,
    width: 1800
  },
  {
    title: 'Mobile Double Banner',

    height: 100,
    width: 320
  }
]

const Bannersizes = () => {
  return (
    <>
      <PageMeta
        pageData={{
          title: 'Best Places to Live | Muddy Stilettos',
          image:
            'https://muddystilettos.co.uk/images/best-places-to-live/Leader.jpg'
        }}
      />
      <Header />
      {Banners.map((banner, index) => (
        <>
          <Content>
            <h1>Example Module between Adverts</h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
              tempus hendrerit tristique. Donec eleifend, erat at mollis
              pulvinar, ex enim euismod nibh, et elementum dolor turpis a massa.
              Donec efficitur faucibus justo non pellentesque. Donec cursus leo
              a nisi tempor feugiat. Integer in dolor quis mi cursus dapibus vel
              non lorem. Vestibulum ante ipsum primis in faucibus orci luctus et
              ultrices posuere cubilia curae; Nulla dictum lectus risus, sed
              auctor arcu cursus ac.
            </p>
            <p>
              Proin consequat eros quis tortor ultricies molestie. Quisque metus
              turpis, scelerisque eu sem quis, blandit rutrum tortor. Sed a
              magna pulvinar, ullamcorper dui ut, laoreet lectus. Nunc porta
              nulla vel nisi tempor aliquet. In facilisis lacinia ante, in
              imperdiet sapien varius eget. Sed eget enim sit amet est semper
              faucibus. Integer commodo dolor dolor, sit amet fringilla nibh
              pellentesque sed.
            </p>
            <p>
              Nulla facilisi. Nunc tempor commodo dolor, non fermentum massa
              rhoncus vel. Donec varius efficitur vulputate. Sed sed elementum
              nisl. Proin venenatis laoreet pretium. Sed at enim odio.
              Pellentesque at ultricies augue. Ut venenatis massa eu ex faucibus
              hendrerit. Nam ac lectus vitae velit dignissim congue. Vivamus ac
              nisl feugiat urna pulvinar congue vestibulum sit amet elit. Nullam
              felis nulla, ullamcorper in mi ac, euismod facilisis arcu. Donec
              metus orci, dapibus quis tortor eu, posuere condimentum orci. Nunc
              tempor iaculis augue et sollicitudin. Curabitur at metus eget
              magna euismod sollicitudin. In vel orci dictum, vulputate quam sit
              amet, tincidunt diam. Pellentesque sagittis orci metus, nec
              porttitor magna tristique vitae.
            </p>
            <p>
              Quisque pellentesque tristique quam quis aliquet. Phasellus eget
              nulla nunc. Praesent in urna pretium, blandit est et, ornare dui.
              Maecenas eget enim vel dolor faucibus vehicula. Aliquam ut ornare
              dui. Aenean magna ligula, finibus id nisi blandit, sollicitudin
              scelerisque felis. Fusce quis iaculis diam. Ut quis molestie arcu.
              Suspendisse enim nisi, fermentum quis lacus at, fermentum interdum
              velit.
            </p>
            <p>
              Praesent iaculis cursus neque, sed sagittis lectus scelerisque at.
              Donec augue velit, varius mattis magna sit amet, euismod lacinia
              tortor. Duis efficitur nulla a eros mattis porta. Etiam tincidunt
              lectus in nunc sollicitudin, at pellentesque dui euismod. Quisque
              non orci varius libero placerat mollis at sed augue. Curabitur et
              posuere enim, ut tempor urna. Nunc dui ipsum, faucibus sit amet
              orci ut, tincidunt varius lorem.
            </p>
          </Content>
          <div key={index} className={BannerStyles.Wrapper}>
            <div
              className="advert"
              style={{
                backgroundColor: 'red',
                height: banner.height,
                width: '100%',
                maxWidth: banner.width,
                margin: '0 auto',
                position: 'relative'
              }}
            >
              <div
                className="pseudo"
                style={{
                  content: '',
                  paddingBottom: `${(banner.height / banner.width) * 100}%`,
                  display: 'block'
                }}
              />
              <p
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  margin: 0
                }}
              >
                {banner.title}
              </p>
            </div>
          </div>
        </>
      ))}
      <Footer />
    </>
  )
}

export default Bannersizes
